<template>
  <div class="box">
    <div class="title">
      <div class="rule">
        <div v-if="this.$route.path!==('/profile')" id="back" class="fa fa-angle-left"
             @click="backToTicketRulesList"></div>
        <span class="name">{{ $t("singleTicketRule.ticketRule") }}#{{ ticketRule.id }}</span>
      </div>
    </div>
    <div class="body">
      <form autocomplete="off">
        <div class="input-field col s12">
          <select id="selectTicketType" v-model="ticketRule.ticketType" class="changeable" disabled>
            <option v-for="ticketType in this.ticketTypes" :key="ticketType.id" v-bind:value="ticketType.name">
              {{ $t("ticketType." + ticketType.name) }}
            </option>
          </select>
          <label class="active">{{ $t("singleTicketRule.ticketType") }}</label>
        </div>

        <div class="input-field col s12">
          <select id="selectTicketStatus" v-model="ticketRule.status" class="changeable" disabled>
            <option v-for="status in this.statuses" :key="status" v-bind:value="status">
              {{ $t("recordStatus." + status) }}
            </option>
          </select>
          <label class="active">{{ $t("singleTicketRule.ticketStatus") }}</label>
        </div>

        <div class="input-field col s12">
          <select id="selectAssignedUser" class="changeable" v-model="ticketRule.assigned" disabled>
            <option v-for="admin in admins" :key="admin.id" v-bind:value="admin.username">
              {{ admin.username }}
            </option>
          </select>
          <label class="active">{{ $t("singleTicketRule.assigned") }}</label>
        </div>

        <div class="input-field col">
          <input id="comments" type="text" class="changeable" v-model="ticketRule.comments" disabled>
          <label for="comments" class="active">{{ $t("singleTicketRule.comments") }}</label>
        </div>

        <div id="controller">
          <button type="button" id="edit" class="btn waves-effect waves-light" @click="editTicketFields">
            {{ $t("singleTicketRule.edit") }}
            <i class="material-icons right">create</i>
          </button>

          <button type="button" id="submit-edit" class="btn waves-effect waves-light" @click="editRequest()">
            {{ $t("singleTicketRule.submit") }}<i class="material-icons right">done</i>
          </button>

          <button type="button" id="reset" class="btn waves-effect waves-light" @click="resetFields()">
            {{ $t("singleTicketRule.reset") }}<i class="material-icons right">cancel</i>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import Link from "../utils/Link";
import $ from "jquery";
import Vue from "vue";
import VueToast from "vue-toast-notification";

export default {
  data() {
    let data = {
      ticketRule: {},
      ticketTypes: [],
      admins: {},
      statuses: [
        "ACTIVE", "NOT_ACTIVE"
      ]
    }
    return this.initialization(data);
  },

  methods: {
    getTicketRuleId() {
      return window.location.pathname.split('/')[2];
    },

    initialization(data) {
      this.getTicketRule(this.getTicketRuleId()).then(d => {
        data.ticketRule = d;
      });

      this.getTicketTypes().then(d => {
        data.ticketTypes = d;
      });

      this.getAdminUsers().then(admins => {
        data.admins = admins;
      })
      return data;
    },

    editTicketFields() {
      $('.changeable').prop('disabled', false);
      $('#edit').css('display', 'none');
      $('#reset').css('display', 'block')
      $('#submit-edit').css('display', 'block');
    },

    resetFields() {
      this.initialization(this.$data);
      $('.changeable').prop('disabled', true);
      $('#edit').css('display', 'block');
      $('#reset').css('display', 'none');
      $('#submit-edit').css('display', 'none');
    },

    editRequest() {
      this.editTicketRule();
      $('.changeable').prop('disabled', true);
      $('#edit').css('display', 'block');
      $('#reset').css('display', 'none');
      $('#submit-edit').css('display', 'none');
    },

    backToTicketRulesList() {
      this.$router.push('/ticketRules');
    },

    getTicketRule(id) {
      let headers = Link.methods.getHeaders();
      return axios.get(
          Link.methods.getOneTicketRuleUrl(id),
          {headers}
      ).then(res => {
        return res.data;
      }).catch(() =>
          this.createToast(this.$t("singleTicketRule.ticketRuleNotFound"), "error")
      );
    },

    getTicketTypes() {
      const headers = Link.methods.getHeaders();
      return axios.get(
          Link.methods.getTicketTypesUrl(),
          {headers}
      ).then(res => {
          return res.data;
      }).catch(() =>
          this.createToast(this.$t("singleTicketRule.ticketTypesNotFound"), "error")
      );
    },

    getAdminUsers() {
      const headers = Link.methods.getHeaders();
      return axios.get(
          Link.methods.getAllUsersUrl(),
          {headers}
      ).then(res => {
        if (res != null && res.data != null) {
          return res.data.filter((it) => it.role === 'ADMIN');
        } else {
          return [];
        }
      }).catch(() =>
          this.createToast(this.$t("singleTicketRule.usersNotFound"), "error")
      );
    },

    editTicketRule() {
      const headers = Link.methods.getHeaders();
      return axios.put(
          Link.methods.getTicketRuleEditUrl(),
          this.ticketRule,
          {headers}
      ).then(() =>
          this.createToast(this.$t("singleTicketRule.ticketRuleEdited"), "success")
      ).catch(() => this.createToast(this.$t("singleTicketRule.ticketRuleNotEdited"), "error")
      );
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 3000
      });
    }
  }
}
</script>


<style scoped lang="scss">
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import '../../../public/materialize-src/sass/materialize.scss';
@import '../../../public/styles/hints.scss';
@import "../../../public/styles/singleTicketRule.scss";
</style>